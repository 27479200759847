/* Style the horizontal line of the first axis */
.x-axis path {
    stroke: #c1c1c1;
}

/* Style the ticks of the first axis*/
.x-axis .tick line {
    stroke: #c1c1c1;
}

/* Hide the line of the second axis*/
.xx-axis path {
    display: none;
}

.y-left-axis path {
    stroke: #c1c1c1;
}

/* Style the ticks of the first axis*/
.y-left-axis .tick line {
    stroke: #c1c1c1;
}

/* Style the ticks of the first axis*/
.y-left-axis .tick text {
    fill: #c1c1c1;
    stroke: 'none'
}

.y-right-axis path {
    stroke: #c1c1c1;
}

/* Style the ticks of the first axis*/
.y-right-axis .tick line {
    stroke: #c1c1c1;
}

/* Style the ticks of the first axis*/
.y-right-axis .tick text {
    fill: #c1c1c1;
    stroke: 'none'
}
