.x-axis path {
    stroke: #c1c1c1;
}

/* Style the ticks of the first axis*/
.x-axis .tick line {
    stroke: #c1c1c1;
}

/* Hide the line of the second axis*/
.xx-axis path {
    display: none;
}